var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", [_vm._v("Nachricht schreiben")]),
          _vm.ready
            ? _c(
                "CCardBody",
                [
                  _c("div", [
                    _vm._v("Empfänger: " + _vm._s(_vm.data.receiverName))
                  ]),
                  _c("div", [_vm._v("Sender: " + _vm._s(_vm.data.senderName))]),
                  _c("ValidatedInput", {
                    attrs: {
                      field: _vm.$v.message.subject,
                      "data-cy": "subject-field",
                      value: _vm.$v.message.subject.$model,
                      translationKey: "message.subject"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.message.subject,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedTextArea", {
                    attrs: {
                      value: _vm.$v.message.body.$model,
                      field: _vm.$v.message.body,
                      "data-cy": "body-field",
                      rows: "5",
                      translationKey: "message.body"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.message.body, "$model", $event)
                      }
                    }
                  }),
                  _c(
                    "CButton",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        value: "Speichern",
                        color: "primary",
                        "data-cy": "submit",
                        disabled: !_vm.canSave
                      },
                      on: { click: _vm.handle }
                    },
                    [_vm._v(" Senden ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }